import { StorageService } from './services/storage';
import { QueryParamService } from './services/queryParamService';

/**
 * JS component for actions in the coupon
 */
export function Coupon() {
  const storageService = new StorageService();
  const queryParamService = new QueryParamService();

  /**
   * Initialize the component.
   */
  function init() {
    validateCoupon();
  }

  /**
   * Send a request to STDcheck API to validate the url or local storage coupon code.
   */
  function validateCoupon() {
    const couponCode = queryParamService.getQueryParam('coupon') || storageService.coupon?.coupon_code;
    if (!couponCode) {
      return;
    }

    return fetch(`${process.env.API_URL}/coupon/${couponCode}`)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.deleted_at) {
          displayInvalidCouponWidget(data);

          return;
        }

        displayCouponWidget(data);
      })
      .catch(function () {
        storageService.coupon = null;
      });
  }

  /**
   * If the coupon has a name (to be displayed in the widget), create an element from
   * coupon_selected_widget template and append it to the DOM.
   *
   * @param { Coupon } coupon
   */
  function displayCouponWidget(coupon) {
    if (!coupon.coupon_name) {
      return;
    }

    document.getElementById('header-bar').replaceWith(createElementFromHTML(tmpl('coupon_selected_widget', coupon)));
    modifyHeaderLayout();
    storageService.coupon = {
      coupon_code: coupon.coupon_code,
      coupon_name: coupon.coupon_name,
      coupon_type: coupon.coupon_type,
    };
  }

  /**
   * Display invalid coupon widget when it is expired
   *
   * @param data
   */
  function displayInvalidCouponWidget(coupon) {
    document.getElementById('header-bar').replaceWith(createElementFromHTML(tmpl('coupon_invalid_widget', coupon)));
    modifyHeaderLayout();
    storageService.coupon = null;
  }

  /**
   * Modify the header layout by moving the My Account button and phone number to the header when a coupon is applied.
   */
  function modifyHeaderLayout() {
    document.querySelector('.header').classList.add('coupon-applied-header');
    document.getElementById('header-cta-button').classList.add('hide');
    document
      .querySelector('.header__menu ul')
      .appendChild(createElementFromHTML(tmpl('coupon_applied_header_buttons', {})));
    document
      .querySelectorAll('.coupon-applied-menu-buttons .animated-button-element')
      .forEach((button) => button.addEventListener('click', () => (window.location.href = button.dataset.href)));
  }

  return {
    init,
  };
}
