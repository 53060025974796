import { StorageService } from './services/storage';
import { Coupon } from './coupon';

('use strict');

$(document).ready(function () {
  storeAccountToken();
  const coupon = new Coupon();
  coupon.init();
});

/**
 * Store the account token.
 */
function storeAccountToken() {
  const storageService = new StorageService();
  getCookie('account_token')
    .then(function (token) {
      storageService.token = { token: token };
    })
    .catch(function () {
      storageService.localStorage.remove('ngStorage-token');
    });
}

/**
 * Get a cookies value by its name.
 *
 * @param   name name of the cookies value to get.
 * @returns {Promise<string>}
 */
function getCookie(name) {
  return new Promise(function (resolve, reject) {
    if (
      !document.cookie.split('; ').some(function (cookieKeyValue) {
        const cookieKeyValueParts = cookieKeyValue.split('=');
        if (cookieKeyValueParts[0] === name) {
          resolve(cookieKeyValueParts[1]);
          return true;
        }

        return false;
      })
    ) {
      reject();
    }
  });
}

/**
 * Creates a HTML element from the string passed.
 * @param {string} htmlString Containing the string to be parsed into an html element.
 * @return {ChildNode} Returns the converted string as HTML
 */
function createElementFromHTML(htmlString) {
  var div = document.createElement('div');
  div.innerHTML = htmlString.trim();

  // Change this to div.childNodes to support multiple top-level nodes
  return div.firstChild;
}

window.createElementFromHTML = createElementFromHTML;

/**
 * This function will work as our javascript "templating" engine function.
 */
(function () {
  'use strict';
  var cache = {};

  window.tmpl = function tmpl(str, data) {
    // Figure out if we're getting a template, or if we need to
    // load the template - and be sure to cache the result.
    var fn = !/\W/.test(str)
      ? (cache[str] = cache[str] || tmpl(document.getElementById(str).innerHTML))
      : // Generate a reusable function that will serve as a template
        // generator (and which will be cached).
        new Function(
          'obj',
          'var p=[],print=function(){p.push.apply(p,arguments);};' +
            // Introduce the data as local variables using with(){}
            "with(obj){p.push('" +
            // Convert the template into pure JavaScript
            str
              .replace(/[\r\t\n]/g, ' ')
              .split('<%')
              .join('\t')
              .replace(/((^|%>)[^\t]*)'/g, '$1\r')
              .replace(/\t=(.*?)%>/g, "',$1,'")
              .split('\t')
              .join("');")
              .split('%>')
              .join("p.push('")
              .split('\r')
              .join("\\'") +
            "');}return p.join('');"
        );

    // Provide some basic currying to the user
    return data ? fn(data) : fn;
  };
})();

/**
 * add events that should happen after document is ready.
 */
(function () {
  'use strict';

  let menuInput, menuInputContainer, findaLabMenuSubmitButton, storageService;

  $(document).ready(function () {
    storageService = new StorageService();
    // add lab hours button click events.
    $('body').delegate('[data-std-hours-link]', 'click', toggleHours);

    initFindALabMenuInput();
  });

  /**
   * Initializes the find a lab functionality in the menu.
   */
  function initFindALabMenuInput() {
    findaLabMenuSubmitButton = $('#menu-findNearLabSubmit');
    menuInput = $('#menu-findalab-zipcode');
    menuInputContainer = menuInput.parents('ul.submenu');

    [menuInput, findaLabMenuSubmitButton].forEach(function (element) {
      element.focus(function () {
        menuInputContainer.addClass('std-findalab-menu-show-dropdown-container');
      });
      element.blur(function (e) {
        if (
          e.relatedTarget === null ||
          (!$(e.relatedTarget).is(findaLabMenuSubmitButton) && !$(e.relatedTarget).is(menuInput))
        ) {
          menuInputContainer.removeClass('std-findalab-menu-show-dropdown-container');
        }
      });
    });

    $('#menu-findalab-form').on('submit', searchForLab);
  }

  /**
   * Redirects to the find a lab map with the entered zip code.
   */
  function searchForLab(e) {
    e.preventDefault();
    menuInputContainer.addClass('std-findalab-menu-show-dropdown-container');
    const zipCode = menuInput.val();
    if (!validateZipCode(zipCode)) {
      return;
    }

    storageService.zipCode = zipCode;
    document.location.href = findaLabMenuSubmitButton.data('findalab-url');
  }

  /**
   * Validates the find a lab zip code or displays an invalid zip code message when the zip is invalid.
   *
   * @param zipCode string the zip code to validate.
   */
  function validateZipCode(zipCode) {
    let valid = /^[0-9]{5}(?:-[0-9]{4})?$/.test(zipCode.replace(/\s+$/g, ''));

    if (valid) {
      $('.menu-invalid-zip-code').addClass('is-hidden');
    } else {
      $('.menu-invalid-zip-code').removeClass('is-hidden');
    }

    return valid;
  }

  /**
   * Toggle the Hours of the lab location
   * @param {event} event The observable event
   */
  function toggleHours(event) {
    event.preventDefault();
    var $table = $(this).siblings('[data-std-hours-table]');
    $(this).text($table.is(':visible') ? 'Show Hours ▼' : 'Hide Hours ▲');
    $table.slideToggle(300);
  }
})();

(function () {
  $(document).ready(function () {
    $('.wistia_mousedown').click(function (event) {
      event.preventDefault();
      var videoId = $(this).data('id');
      loadScript('https://fast.wistia.com/assets/external/E-v1.js')
        .then(function () {
          showOverlayLoading();
          startVideo(videoId);
        })
        .catch(function () {
          console.error('Wistia javascript file not loaded.');
        });
    });
  });

  /**
   * Loads a JavaScript file and returns a Promise for when it is loaded
   */
  function loadScript(src) {
    return new Promise(function (resolve, reject) {
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.crossOrigin = 'anonymous';
      script.onload = resolve;
      script.onerror = reject;
      script.src = src;
      document.head.appendChild(script);
    });
  }

  /**
   * Adds an overlay loading scene.
   */
  function showOverlayLoading() {
    var loading = document.createElement('div');
    loading.id = 'loading_popover_overlay';
    loading.className = 'wistia_popover_loading';
    loading.innerHTML = '<i class="fa fa-spinner fa-pulse"></i>';
    document.body.appendChild(loading);
  }

  /**
   * Removes the overlay loading.
   */
  function hideOverlayLoading() {
    var loading = document.getElementById('loading_popover_overlay');
    if (!('remove' in Element.prototype)) {
      // IE 11
      loading.parentNode.removeChild(loading);
    } else {
      loading.remove();
    }
  }

  /**
   * Wait until wistia become available and plays video.
   */
  function startVideo(videoId) {
    setTimeout(function () {
      window._wq = window._wq || [];
      _wq.push({
        id: videoId,
        onReady: function (video) {
          video.play();
          hideOverlayLoading();
        },
      });
    }, 200);
  }
})();
